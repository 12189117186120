import React from "react"

import SEO from "../components/Seo"

const NotFoundPage = () => (
    <div>
        <SEO title="404: Not found" />
        <h1>404: Not Found</h1>
    </div>
)

export default NotFoundPage
